import { AiOutlineTransaction } from "react-icons/ai";
import { FaRegUser } from "react-icons/fa";
import { MdCategory, MdOutlineDashboard, MdProductionQuantityLimits } from "react-icons/md";

export const sideBarLinks = [
  {
    id: 1,
    name: "Dashboard",
    url: "/",
    icon: <MdOutlineDashboard />,
  },
  {
    id: 2,
    name: "Users",
    url: "/users",
    icon: <FaRegUser />,
  },
  {
    id: 3,
    name: "Orders",
    url: "/admin/order-history",
    icon: <AiOutlineTransaction />,
  },
  {
    id: 4,
    name: "Category",
    url: "/admin/category",
    icon: <MdCategory />,
  },
  {
    id: 5,
    name: "Products",
    url: "/admin/product",
    icon: <MdProductionQuantityLimits />,
  },
];
