export const GET_ALL_ORDER_REQUEST = "GET_ALL_ORDER_REQUEST";
export const GET_ALL_ORDER_SUCCESS = "GET_ALL_ORDER_SUCCESS";
export const GET_ALL_ORDER_FAIL = "GET_ALL_ORDER_FAIL";

export const GET_ORDER_DETAIL_REQUEST = "GET_ORDER_DETAIL_REQUEST";
export const GET_ORDER_DETAIL_SUCCESS = "GET_ORDER_DETAIL_SUCCESS";
export const GET_ORDER_DETAIL_FAIL = "GET_ORDER_DETAIL_FAIL";


export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL";

export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL";

export const CLEAR_ORDER_ERROR = "CLEAR_ORDER_ERROR";
export const CLEAR_ORDER_STATE = "CLEAR_ORDER_STATE";
export const CLEAR_DELETE_ORDER_STATE = "CLEAR_DELETE_ORDER_STATE";
