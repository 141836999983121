import {
    CLEAR_DELETE_CATEGORY_STATE,
    CLEAR_CATEGORY_ERROR,
    CLEAR_CATEGORY_STATE,
    CREATE_CATEGORY_FAIL,
    CREATE_CATEGORY_REQUEST,
    CREATE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAIL,
    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_SUCCESS,
    GET_ALL_CATEGORY_FAIL,
    GET_ALL_CATEGORY_REQUEST,
    GET_ALL_CATEGORY_SUCCESS,
    GET_CATEGORY_DETAIL_FAIL,
    GET_CATEGORY_DETAIL_REQUEST,
    GET_CATEGORY_DETAIL_SUCCESS,
    UPDATE_CATEGORY_FAIL,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_SUCCESS,
  } from "../constant/categoryConstant";
  
  // CREATE NEW BATCH BY ADMIN
  export const createCATEGORYReducer = (state = {}, action) => {
    switch (action.type) {
      case CREATE_CATEGORY_REQUEST:
        return {
          loading: true,
          categoryCreate: {},
        };
      case CREATE_CATEGORY_SUCCESS:
        return {
          loading: false,
          categoryCreate: action.payload,
        };
      case CREATE_CATEGORY_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_CATEGORY_ERROR:
        return {
          loading: false,
          error: null,
        };
  
      case CLEAR_CATEGORY_STATE:
        return {
          loading: false,
          error: null,
          categoryCreate: {},
        };
  
      default:
        return state;
    }
  };
  
  // GET ALL category
  export const getAllCATEGORYReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_ALL_CATEGORY_REQUEST:
        return {
          loading: true,
          category: {},
        };
      case GET_ALL_CATEGORY_SUCCESS:
        return {
          loading: false,
          category: action.payload,
        };
      case GET_ALL_CATEGORY_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_CATEGORY_ERROR:
        return {
          loading: false,
          error: null,
        };
  
      case CLEAR_CATEGORY_STATE:
        return {
          loading: false,
          error: null,
          category: {},
        };
  
      default:
        return state;
    }
  };
  
  // GET ALL BATCH BY ID
  export const getCATEGORYByIdReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_CATEGORY_DETAIL_REQUEST:
        return {
          loading: true,
          categoryDetails: {},
        };
      case GET_CATEGORY_DETAIL_SUCCESS:
        return {
          loading: false,
          categoryDetails: action.payload,
        };
      case GET_CATEGORY_DETAIL_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_CATEGORY_ERROR:
        return {
          loading: false,
          error: null,
        };
  
      case CLEAR_CATEGORY_STATE:
        return {
          loading: false,
          error: null,
          categoryDetails: {},
        };
  
      default:
        return state;
    }
  };
  
  //   UPDATE BLOG REDUCER
  export const updateCATEGORYReducer = (state = {}, action) => {
    switch (action.type) {
      case UPDATE_CATEGORY_REQUEST:
        return {
          loading: true,
          updateData: {},
        };
      case UPDATE_CATEGORY_SUCCESS:
        return {
          loading: false,
          updateData: action.payload,
        };
      case UPDATE_CATEGORY_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_CATEGORY_ERROR:
        return {
          loading: false,
          error: null,
        };
  
      case CLEAR_CATEGORY_STATE:
        return {
          loading: false,
          error: null,
          updateData: {},
        };
  
      default:
        return state;
    }
  };
  
  // DELETE BATCH BY ADMIN
  export const deleteCATEGORYByAdminReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_CATEGORY_REQUEST:
        return {
          loading: true,
          categoryDelete: {},
        };
      case DELETE_CATEGORY_SUCCESS:
        return {
          loading: false,
          categoryDelete: action.payload,
        };
      case DELETE_CATEGORY_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_CATEGORY_ERROR:
        return {
          loading: false,
          error: null,
        };
      case CLEAR_DELETE_CATEGORY_STATE:
        return {
          loading: false,
          error: null,
          categoryDelete: {},
        };
  
      default:
        return state;
    }
  };
  