import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import Header from "../../components/layout/Header";
import { MdCategory } from "react-icons/md";
import CustomInput from "../../components/reusable/CustomInput";
import "../../styles/productCreate.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCATEGORYErrors,
  clearCATEGORYState,
  getCATEGORYById,
  updateCATEGORY,
} from "../../redux/actions/categoryAction";
import { toast } from "react-toastify";
import CustomButton from "../../components/reusable/CustomButton";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import { FaArrowLeftLong } from "react-icons/fa6";
import Loader from "../../components/reusable/Loader";

const CategoryEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");

  const {
    loading: detailLoading,
    error: detailError,
    categoryDetails,
  } = useSelector((state) => state.getCategoryDetails);

  useEffect(() => {
    if (id) {
      dispatch(getCATEGORYById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (detailError) {
      toast.error(detailError);
      dispatch(clearCATEGORYErrors());
    }
    if (categoryDetails) {
      setTitle("");
    }
  }, [dispatch, detailError, categoryDetails]);

  const { loading, error, updateData } = useSelector(
    (state) => state.updateCategoryDetails
  );

  const handleSubmit = () => {
    dispatch(updateCATEGORY(id, title));
  };

  useEffect(() => {
    if (error) {
      dispatch(clearCATEGORYErrors());
      toast.error(error);
    }
    if (updateData?.success) {
      toast.success(updateData?.message);
      dispatch(clearCATEGORYState());
      navigate("/admin/category");
    }
  }, [dispatch, error, updateData?.success, updateData?.message, navigate]);

  return (
    <Fragment>
      {detailLoading ? (
        <Loader />
      ) : (
        <Layout>
          <Header title="Edit Category" icon={<MdCategory />} />

          <div className="go_back">
            <button onClick={() => navigate(-1)}>
              <FaArrowLeftLong />
            </button>
          </div>
          <section className="product_create">
            <div className="input_grid">
              <CustomInput
                label="Categor Name"
                placeholder="Honey"
                name="title"
                type="text"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              />
            </div>
            <div className="submit_btn">
              <CustomButton
                title={loading ? <ButtonLoader /> : "Create"}
                width="90px"
                onClick={handleSubmit}
              />
            </div>
          </section>
        </Layout>
      )}
    </Fragment>
  );
};

export default CategoryEdit;
