export const GET_ALL_CATEGORY_REQUEST = "GET_ALL_CATEGORY_REQUEST";
export const GET_ALL_CATEGORY_SUCCESS = "GET_ALL_CATEGORY_SUCCESS";
export const GET_ALL_CATEGORY_FAIL = "GET_ALL_CATEGORY_FAIL";

export const GET_CATEGORY_DETAIL_REQUEST = "GET_CATEGORY_DETAIL_REQUEST";
export const GET_CATEGORY_DETAIL_SUCCESS = "GET_CATEGORY_DETAIL_SUCCESS";
export const GET_CATEGORY_DETAIL_FAIL = "GET_CATEGORY_DETAIL_FAIL";

export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAIL = "CREATE_CATEGORY_FAIL";

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";

export const CLEAR_CATEGORY_ERROR = "CLEAR_CATEGORY_ERROR";
export const CLEAR_CATEGORY_STATE = "CLEAR_CATEGORY_STATE";
export const CLEAR_DELETE_CATEGORY_STATE = "CLEAR_DELETE_CATEGORY_STATE";
