import React from "react";
import styled from "styled-components";

const MyInput = styled.div`
  margin-bottom: 25px;
  @media screen and (max-width: 575px) {
    margin-bottom: 20px;
  }
  > label {
    display: block;
    width: 100%;
    margin-bottom: 7px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #124b04;

    > .impStar {
      color: red;
      font-size: 18px;
    }
    @media screen and (max-width: 575px) {
      font-size: 13px;
    }
  }
  .input_icon {
    position: relative;

    > .icon {
      z-index: 1;
      height: 40px;
      width: 40px;
      position: absolute;
      color: #124b04;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 575px) {
        height: 40px;
        width: 40px;
        font-size: 13px;
      }
      > svg {
        color: #a9b5bb;
        z-index: 1;
        position: absolute;
        width: 40%;
        height: 40%;
      }
    }

    > .eyeIcon {
      height: 40px;
      width: 40px;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #124b04;
      @media screen and (max-width: 575px) {
        height: 40px;
        width: 40px;
        font-size: 13px;
      }
      > svg {
        color: #a9b5bb;
        z-index: 1;
        position: absolute;
        width: 40%;
        height: 40%;
      }
    }
  }
  .input_text {
    display: block;
    width: 100%;
    height: auto;
    min-height: 100px; 
    background: linear-gradient(
      115.85deg,
      rgba(255, 255, 255, 0.4) -185.19%,
      rgba(255, 255, 255, 0) 129.07%
    );
    backdrop-filter: blur(21px);
    border-radius: 4px;
    border: 1px solid #124b04;
    outline: none;
    box-sizing: border-box;
    padding: 10px;
    color: #124b04;
    font-size: 14px;
    resize: vertical;
    @media screen and (max-width: 575px) {
      font-size: 13px;
    }
  }
`;

const CustomTextarea = ({
  icon,
  label,
  onChange,
  placeholder,
  name,
  value,
  impStar,
  readOnly,
  borderColor,
}) => {
  return (
    <MyInput className="myInput">
      {label && (
        <label>
          {label} {impStar && <abbr className="impStar">{impStar}</abbr>}
        </label>
      )}
      <div className="input_icon">
        {icon && <span className="icon">{icon}</span>}
        <textarea
          className="input_text"
          onChange={onChange}
          placeholder={placeholder}
          name={name}
          value={value}
          readOnly={readOnly}
          style={{
            border: borderColor && `1px solid ${borderColor}`,
            paddingLeft: icon && "40px",
          }}
        />
      </div>
    </MyInput>
  );
};

export default CustomTextarea;
