import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import Header from "../../components/layout/Header";
import { MdDelete, MdProductionQuantityLimits } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearDeleteProductState,
  clearProductErrors,
  deleteProductByAdmin,
  getAllProduct,
} from "../../redux/actions/productAction";
import { Popconfirm, Table } from "antd";
import SearchInput from "../../components/reusable/CustomSearch";
import CustomButton from "../../components/reusable/CustomButton";
import { FaRegEdit } from "react-icons/fa";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import Loader from "../../components/reusable/Loader";
import { toast } from "react-toastify";

const ProductList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(null);

  // Pagination state
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { loading, error, products } = useSelector(
    (state) => state.getAllProduct
  );

  useEffect(() => {
    if (error) {
      dispatch(clearProductErrors());
    }
    if (products?.products) {
      setFilteredData(products?.products);
    }
  }, [error, products, dispatch]);

  const handleSearch = () => {
    const filtered = products?.products?.filter((item) =>
      item.title.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    dispatch(getAllProduct());
  }, [dispatch]);

  //   DELETE CATEGORY  ACTION STARTS
  const {
    loading: deleteLoading,
    error: deleteError,
    productDelete,
  } = useSelector((state) => state.deleteProductByAdmin);

  const handleDelete = (id) => {
    dispatch(deleteProductByAdmin(id));
  };

  useEffect(() => {
    if (deleteError) {
      toast.error(deleteError);
      dispatch(clearProductErrors());
    }
    if (productDelete?.success) {
      toast.success(productDelete?.message);
      dispatch(clearDeleteProductState());
      dispatch(getAllProduct());
    }
  }, [dispatch, deleteError, productDelete?.message, productDelete?.success]);

  //   DELETE PRODUCT  ACTION ENDS

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Stock Quantity",
      dataIndex: "stockQuantity",
      key: "stockQuantity",
    },
    {
      title: "Price",
      dataIndex: "originalPrice",
      key: "originalPrice",
    },
    {
      title: "Discount Price",
      dataIndex: "discountedPrice",
      key: "discountedPrice",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },

    {
      title: "Available Size",
      dataIndex: "availableSize",
      key: "availableSize",
      render: (text) => <span>{text?.join(",")}</span>,
    },
    {
      title: "COD",
      dataIndex: "isCodAvailable",
      key: "isCodAvailable",
      render: (text) => <span>{text ? "Yes" : "No"}</span>,
    },

    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => new Date(text).toLocaleString(),
    },

    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span className="action_btn">
          <button
            className="primary"
            onClick={() => navigate(`/admin/category-edit/${record._id}`)}
            style={{ marginRight: 8 }}
          >
            <FaRegEdit />
          </button>
          <Popconfirm
            title="Are you sure you want to delete this category?"
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <button className="danger">
              {deleteLoading ? <ButtonLoader /> : <MdDelete />}
            </button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <Header title="Products" icon={<MdProductionQuantityLimits />} />
          <section className="product_list">
            <div className="create_btn">
              <CustomButton
                title="Add New"
                width="140px"
                onClick={() => navigate("/admin/product-create")}
              />
              <SearchInput
                onSearch={handleSearch}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </div>
            <div className="table_list">
              <Table
                columns={columns}
                dataSource={filteredData}
                rowKey="_id"
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  total: filteredData?.length,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                  onChange: handlePageChange,
                  onShowSizeChange: handlePageSizeChange,
                }}
              />
            </div>
          </section>
        </Layout>
      )}
    </Fragment>
  );
};

export default ProductList;
