import React, { Fragment, useState } from "react";
import "../../styles/header.scss";
import { FaUser } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useAuth } from "../../context/userContext";
import { IoMdLogOut } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/actions/userAction";
const Header = ({ title, icon }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [open, setOpen] = useState(false);
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };
  return (
    <Fragment>
      <section className="header_wrapper">
        <div className="title">
          <span>{icon}</span>
          <h2>{title}</h2>
        </div>
        <div className="user_profile">
          {auth?.user && (
            <>
              <div className="auth_profile" onClick={() => setOpen(!open)}>
                <FaUser />
                <h2>{auth?.user?.name}</h2>
                <RiArrowDropDownLine />
              </div>
              {open && (
                <button className="logout_btn" onClick={() => handleLogout()}>
                  <IoMdLogOut /> Logout
                </button>
              )}
            </>
          )}
        </div>
      </section>
    </Fragment>
  );
};

export default Header;
