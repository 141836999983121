import {
  ALL_USERS_ADMIN_FAIL,
  ALL_USERS_ADMIN_REQUEST,
  ALL_USERS_ADMIN_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_PROFILE_ERRORS,
  CLEAR_STATE,
  CLEAR_USER_LOADING,
  DELETE_USER_ADMIN_FAIL,
  DELETE_USER_ADMIN_REQUEST,
  DELETE_USER_ADMIN_SUCCESS,
  GET_USER_DETAILS_ADMIN_FAIL,
  GET_USER_DETAILS_ADMIN_REQUEST,
  GET_USER_DETAILS_ADMIN_SUCCESS,
  UPDATE_USER_ADMIN_FAIL,
  UPDATE_USER_ADMIN_REQUEST,
  UPDATE_USER_ADMIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_PROFILE_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
} from "../constant/userConstant";

// USER LOGIN REDUCER
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        userInfo: {},
      };

    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        userInfo: action.payload,
      };

    case USER_LOGIN_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        loading: false,
        isAuthenticated: false,
        userInfo: null,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        isAuthenticated: false,
        error: null,
        userInfo: {},
      };
    case CLEAR_USER_LOADING:
      return {
        ...state,
        loading: false,
      };

    case USER_LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        userInfo: {},
      };

    default:
      return state;
  }
};

// GET USER PROFILE REDUCER
export const userProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        userInfo: {},
      };
    case USER_PROFILE_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        userInfo: action.payload,
      };
    case USER_PROFILE_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };
    case CLEAR_PROFILE_ERRORS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: null,
      };

    case USER_LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        userInfo: {},
      };

    default:
      return state;
  }
};

// GET ALL USERS BY ADMIN
export const getUsersByAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_USERS_ADMIN_REQUEST:
      return {
        loading: true,
        allUsers: {},
      };
    case ALL_USERS_ADMIN_SUCCESS:
      return {
        loading: false,
        allUsers: action.payload,
      };
    case ALL_USERS_ADMIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        loading: false,
        allUsers: {},
        error: null,
      };

    default:
      return state;
  }
};

// DELETE USER BY ADMIN
export const deleteUserByAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_USER_ADMIN_REQUEST:
      return {
        loading: true,
        deletedUser: {},
      };
    case DELETE_USER_ADMIN_SUCCESS:
      return {
        loading: false,
        deletedUser: action.payload,
      };
    case DELETE_USER_ADMIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        loading: false,
        deletedUser: {},
        error: null,
      };

    default:
      return state;
  }
};

// DELETE USER BY ADMIN
export const getUserDetailsByAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_DETAILS_ADMIN_REQUEST:
      return {
        loading: true,
        userDetails: {},
      };
    case GET_USER_DETAILS_ADMIN_SUCCESS:
      return {
        loading: false,
        userDetails: action.payload,
      };
    case GET_USER_DETAILS_ADMIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        loading: false,
        userDetails: {},
        error: null,
      };

    default:
      return state;
  }
};

// DELETE USER BY ADMIN
export const updateUserDetailsByAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER_ADMIN_REQUEST:
      return {
        loading: true,
        updatedUser: {},
      };
    case UPDATE_USER_ADMIN_SUCCESS:
      return {
        loading: false,
        updatedUser: action.payload,
      };
    case UPDATE_USER_ADMIN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        loading: false,
        updatedUser: {},
        error: null,
      };

    default:
      return state;
  }
};
