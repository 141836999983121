import React from 'react';
import styled, { keyframes } from 'styled-components';

// Animation for the loader dots
const spin = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
`;

// Styled component for the loader container
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
  background-color: #124b04; /* Light background color */
`;

// Styled component for each dot in the loader
const Dot = styled.div`
  width: 12px;
  height: 12px;
  margin: 0 5px;
  background-color: #4CAF50; /* Loader dot color */
  border-radius: 50%;
  animation: ${spin} 0.6s infinite ease-in-out;
  animation-delay: ${(props) => props.delay}; /* Animation delay for each dot */
`;

const Loader = () => {
  return (
    <LoaderWrapper>
      <Dot delay="0s" />
      <Dot delay="0.2s" />
      <Dot delay="0.4s" />
    </LoaderWrapper>
  );
};

export default Loader;
