import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  createProductReducer,
  deleteProductByAdminReducer,
  getAllProductReducer,
  getProductByIdReducer,
  updateProductReducer,
} from "./reducer/productReducer";
import {
  deleteUserByAdminReducer,
  getUserDetailsByAdminReducer,
  getUsersByAdminReducer,
  updateUserDetailsByAdminReducer,
  userLoginReducer,
  userProfileReducer,
} from "./reducer/userReducer";
import {
  createCATEGORYReducer,
  deleteCATEGORYByAdminReducer,
  getAllCATEGORYReducer,
  getCATEGORYByIdReducer,
  updateCATEGORYReducer,
} from "./reducer/categoryReducer";
import { getAllOrderReducer } from "./reducer/orderReducer";
// get user from sessionStorage
const userFromSessionStorage = sessionStorage.getItem("saajAdminToken")
  ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
  : {};

const reducer = combineReducers({
  createCategory: createCATEGORYReducer,
  getCategoryList: getAllCATEGORYReducer,
  getCategoryDetails: getCATEGORYByIdReducer,
  updateCategoryDetails: updateCATEGORYReducer,
  deleteCategoryReducer: deleteCATEGORYByAdminReducer,
  getAllProduct: getAllProductReducer,
  createProduct: createProductReducer,
  getProductById: getProductByIdReducer,
  updateProduct: updateProductReducer,
  deleteProductByAdmin: deleteProductByAdminReducer,
  userLogin: userLoginReducer,
  profile: userProfileReducer,
  getUsersByAdmin: getUsersByAdminReducer,
  deleteUserByAdmin: deleteUserByAdminReducer,
  getUserDetails: getUserDetailsByAdminReducer,
  updateUserDetailsByAdmin: updateUserDetailsByAdminReducer,
  getAllOrder:getAllOrderReducer
});

// MIDDLEWARE // THUNK
const middleware = [thunk];

// INITIAL STATE
const initialState = {
  userLogin: {
    userInfo: userFromSessionStorage,
  },
};

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
