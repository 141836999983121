import React, { Fragment } from "react";
import Layout from "../components/layout/Layout";
import Header from "../components/layout/Header";
import { MdOutlineDashboard } from "react-icons/md";

const Dashboard = () => {
  return (
    <Fragment>
      <Layout>
        <Header title="Dashboard" icon={<MdOutlineDashboard />} />
        <section className="dashboard_page">
          <div className="sales_card">
            <div className="card">Total Sales</div>
          </div>
        </section>
      </Layout>
    </Fragment>
  );
};

export default Dashboard;
