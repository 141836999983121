import React, { Fragment } from "react";
import Sidebar from "./Sidebar";
import "../../styles/sidebar.scss";
const Layout = ({ children }) => {
  return (
    <Fragment>
      <section className="layout_wrapper">
        <Sidebar />
        <div className="right_section">



            {children}
            
        </div>
      </section>
    </Fragment>
  );
};

export default Layout;
