
import React from 'react';
import { Input, Button } from 'antd';
import { MdSearch } from "react-icons/md";

const SearchInput = ({ onSearch, searchValue, setSearchValue }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Input
        placeholder="Search title"
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        onPressEnter={onSearch}
        style={{ width: 200 }}
      />
      <Button type="primary" onClick={onSearch} icon={<MdSearch />}>
        Search
      </Button>
    </div>
  );
};

export default SearchInput;
