import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import Header from "../../components/layout/Header";
import { MdProductionQuantityLimits } from "react-icons/md";
import CustomInput from "../../components/reusable/CustomInput";
import CustomMultiSelect from "../../components/reusable/CustomMultiSelect";
import "../../styles/productCreate.scss";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../components/reusable/CustomButton";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import { toast } from "react-toastify";
import {
  clearProductErrors,
  createNewProduct,
} from "../../redux/actions/productAction";
import {
  clearCATEGORYErrors,
  getAllCATEGORY,
} from "../../redux/actions/categoryAction";
import CustomTextarea from "../../components/reusable/CustomTextarea";
import { FaPlus } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
const ProductCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [categoryId, setCategoryId] = useState();
  const [availableSize, setAvailableSize] = useState();
  const [images, setImages] = useState([]);
  // const [imagePreview, setImagePreview] = useState();
  const [productData, setProductData] = useState({
    title: "",
    description: "",
    stockQuantity: "",
    originalPrice: "",
    discountedPrice: "",
    isTaxIncluded: true,
    isCodAvailable: true,
  });

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to an array
    setImages(files); // Set images in state
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleImageUpload = (e) => {
  //   if (e.target.name === "image") {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       if (reader?.readyState === 2) {
  //         setImagePreview(reader?.result);
  //       }
  //     };
  //     reader?.readAsDataURL(e.target.files[0]);
  //   }
  // };

  // const handleAddImage = () => {
  //   setImages((prev) => [...prev,  imagePreview]);
  //   setImagePreview();
  // };

  // const handleRemoveImage = (id) => {
  //   const filter = images?.filter((_, idx) => idx !== id);
  //   setImages(filter);
  // };

  const {
    loading: categoryLoading,
    error: categoryError,
    category,
  } = useSelector((state) => state.getCategoryList);

  let categoryList = [];

  category?.categories?.forEach((ele) => {
    categoryList.push({
      label: ele?.title,
      value: ele?._id,
    });
  });

  useEffect(() => {
    dispatch(getAllCATEGORY());
  }, [dispatch]);

  useEffect(() => {
    if (categoryError) {
      toast.error(categoryError);
      dispatch(clearCATEGORYErrors());
    }
  }, [categoryError, dispatch]);

  const { loading, error, productCreate } = useSelector(
    (state) => state.createProduct
  );

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearProductErrors());
    }
    if (productCreate?.success) {
      toast.success(productCreate?.message);
    }
  }, [error, dispatch, productCreate?.success, productCreate?.message]);

  const {
    title,
    description,
    originalPrice,
    discountedPrice,
    stockQuantity,
    isTaxIncluded,
    isCodAvailable,
  } = productData;

  const handleSubmit = () => {
    const updatedPrice = parseInt(originalPrice);
    const updatesDiscount = parseInt(discountedPrice);
    const sizes = availableSize?.map((ele) => ele.label);
    const formData = new FormData();
    formData.set("title", title);
    formData.set("description", description);
    formData.set("originalPrice", updatedPrice);
    formData.set("discountedPrice", updatesDiscount);
    formData.set("stockQuantity", stockQuantity);
    formData.set("isTaxIncluded", isTaxIncluded);
    formData.set("isCodAvailable", isCodAvailable);
    formData.set("category", categoryId?.value);
    formData.set("availableSize", sizes);

    images.forEach((image, index) => {
      formData.append('images', image); // 'images' is the key, image is the file
    });

    dispatch(createNewProduct(formData));
  };

  console.log(images);
  const image = images?.map(item=> console.log(item))
  console.log(image);

  return (
    <Fragment>
      <Layout>
        <Header title="Product Create" icon={<MdProductionQuantityLimits />} />

        <div className="go_back">
          <button onClick={() => navigate(-1)}>
            <FaArrowLeftLong />
          </button>
        </div>
        <section className="product_create">
          <div className="input_grid">
            <CustomInput
              label="Product Name"
              placeholder="ex: a2 desi cow ghee"
              name="title"
              type="text"
              impStar="*"
              value={productData?.title}
              onChange={handleInputChange}
            />
            <CustomInput
              label="Product Price"
              placeholder="Enter product orignal price"
              name="originalPrice"
              type="number"
              impStar="*"
              value={productData.originalPrice}
              onChange={handleInputChange}
            />
            <CustomInput
              label="Discount price"
              placeholder="Enter discount price"
              name="discountedPrice"
              type="number"
              onChange={handleInputChange}
              value={productData.discountedPrice}
              impStar="*"
            />
            <CustomInput
              label="Stock Quantity"
              placeholder="Enter total available items in stock"
              name="stockQuantity"
              type="number"
              impStar="*"
              value={productData.stockQuantity}
              onChange={handleInputChange}
            />

            <CustomMultiSelect
              label="Select product category"
              name="category"
              borderColor="#124b04"
              isMulti={false}
              optionArray={categoryList}
              impStar="*"
              value={categoryId}
              onChange={(val) => setCategoryId(val)}
            />
            <CustomMultiSelect
              label="Choose available size"
              borderColor="#124b04"
              name="availableSize"
              value={availableSize}
              loading={categoryLoading}
              onChange={(val) => setAvailableSize(val)}
              isMulti={true}
              optionArray={[
                {
                  label: "250gm",
                  value: 250,
                },
                {
                  label: "500gm",
                  value: 500,
                },
                {
                  label: "1kg",
                  value: 1,
                },
                {
                  label: "5kg",
                  value: 5,
                },
              ]}
              impStar="*"
            />
            <CustomTextarea
              label="Product Description"
              placeholder="Write about product"
              name="description"
              type="text"
              impStar="*"
              onChange={handleInputChange}
              value={productData?.description}
            />
            <div className="image_upload">
              <label>
                <span>Upload Images </span> <br />
                (Max 4 & Main Front images should be select on first)
              </label>
              <div className="selectImage">
                <input
                  type="file"
                  name="images"
                  accept="image/*"
                  multiple
                  onChange={handleImageChange}
                  // onChange={handleImageUpload}
                />
                {/* {imagePreview !== "" && imagePreview !== undefined && (
                  <div>
                    <img src={imagePreview} alt="preview-image" />
                    <button onClick={handleAddImage}>
                      <FaPlus />
                    </button>
                  </div>
                )} */}
              </div>
              {/* {images?.length > 0 && <p>Preview List :</p>}
              {images?.length > 0 && (
                <div className="image_preview">
                  {images.map((preview, index) => (
                    <div key={index}>
                      <img src={preview} alt={`preview-${index}`} />
                      <button onClick={() => handleRemoveImage(index)}>
                        <RxCross2 />
                      </button>
                    </div>
                  ))}
                </div>
              )} */}
            </div>
          </div>
          <div className="submit_btn">
            <CustomButton
              title={loading ? <ButtonLoader /> : "Create"}
              width="90px"
              onClick={handleSubmit}
            />
          </div>
        </section>
      </Layout>
    </Fragment>
  );
};

export default ProductCreate;
