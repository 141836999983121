import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_DELETE_CATEGORY_STATE,
  CLEAR_CATEGORY_ERROR,
  CLEAR_CATEGORY_STATE,
  CREATE_CATEGORY_FAIL,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  GET_ALL_CATEGORY_FAIL,
  GET_ALL_CATEGORY_REQUEST,
  GET_ALL_CATEGORY_SUCCESS,
  GET_CATEGORY_DETAIL_FAIL,
  GET_CATEGORY_DETAIL_REQUEST,
  GET_CATEGORY_DETAIL_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
} from "../constant/categoryConstant";

// CREATE NEW BATCH
export const createNewCATEGORY =
  (
    title
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_CATEGORY_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("saajAdminToken")
        ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        `/create-category`,
        {
          title
        },
        config
      );

      dispatch({
        type: CREATE_CATEGORY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_CATEGORY_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET ALL BATCHES
export const getAllCATEGORY = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_CATEGORY_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("saajAdminToken")
      ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/all-categories`, config);

    dispatch({
      type: GET_ALL_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_CATEGORY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET BATCH BY ID
export const getCATEGORYById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CATEGORY_DETAIL_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("saajAdminToken")
      ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/category/${id}`, config);

    dispatch({
      type: GET_CATEGORY_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CATEGORY_DETAIL_FAIL,
      payload: error.response.data.message,
    });
  }
};

// UPDATE BLOG ACTION

export const updateCATEGORY =
  (
    id,
    title,
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_CATEGORY_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("saajAdminToken")
        ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/update-category/${id}`,
        {
          title,
        },
        config
      );

      dispatch({
        type: UPDATE_CATEGORY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_CATEGORY_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// DELETE CATEGORY
export const deleteCATEGORYByAdmin = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_CATEGORY_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("saajAdminToken")
      ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`/delete-category/${id}`, config);

    dispatch({
      type: DELETE_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_CATEGORY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearCATEGORYErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_CATEGORY_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearCATEGORYState = () => async (dispatch) => {
  dispatch({ type: CLEAR_CATEGORY_STATE });
};

export const clearDeleteCATEGORYState = () => async (dispatch) => {
  dispatch({ type: CLEAR_DELETE_CATEGORY_STATE });
};
