import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, logout, userProfile } from "../redux/actions/userAction";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";

const userContext = createContext();

const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [auth, setAuth] = useState({
    user: null,
    token: "",
    loading: false,
  });

  // DEFAULT AXIOS
  // axios.defaults.headers.common["Authorization"] = `Bearer ${auth?.token}`;

  const {
    userInfo: profileData,
    isAuthenticated,
    error,
    loading: profileLoading,
  } = useSelector((state) => state.profile); // GET USERDATA FROM PROFILE STATE

  const { userInfo: loginUser } = useSelector((state) => state.userLogin); // GET TOKEN DATA FROM LOGIN STATE

  useEffect(() => {
    const token = loginUser?.token;
    if (token) {
      dispatch(userProfile()); // GET USER PROFILE DATA
    }
    // else if (!token) {
    //   dispatch(userGoogleProfile());
    // }
  }, [dispatch, loginUser?.token]);

  // SET COMING DATA FROM PROFILE STATE
  useEffect(() => {
    if (error && loginUser?.token) {
      toast.error(error);
      dispatch(clearErrors());
      dispatch(logout());
      navigate("/");
    }

    if (isAuthenticated) {
      setAuth({
        user: profileData?.user,
        token: loginUser?.token,
        loading: profileLoading,
      });
    } else if (!isAuthenticated) {
      setAuth({
        user: {},
        token: "",
        loading: false,
      });
    }
  }, [
    isAuthenticated,
    loginUser?.token,
    profileData?.user,
    error,
    dispatch,
    navigate,
    profileLoading,
  ]);

  return (
    <userContext.Provider value={[auth, setAuth]}>
      {children}
    </userContext.Provider>
  );
};

const useAuth = () => useContext(userContext);

export { useAuth, UserProvider };
