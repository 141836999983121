import React, { useState, Fragment, useEffect } from "react";
import { logo } from "../assests/imageModule/image";
import CustomInput from "../components/reusable/CustomInput";
import CustomButton from "../components/reusable/CustomButton";
import "../styles/login.scss";
import { clearErrors, login } from "../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../components/reusable/ButtonLoader";
// import { useNotification } from "../context/notificationContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/userContext";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [auth] = useAuth();
  const { error, loading } = useSelector((state) => state.userLogin);

  const [showPassword, setshowPassword] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const { email, password } = formData;

  const handleSubmit = (e) => {
    dispatch(login(email, password));
  };

  // console.log(userInfo);
  

  useEffect(() => {
    if (error) {
      alert(error);
      dispatch(clearErrors());
    }
    if (auth?.user?.role === 10) {
      navigate("/admin/dashboard");
    }
  });

  return (
    <Fragment>
      <section className="login_wrapper">
        <div className="login_form">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <CustomInput
            label="Email"
            placeholder="Enter email address"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
          <CustomInput
            label="Password"
            placeholder="Enter password"
            type={showPassword ? "text" : "password"}
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            impStar="*"
            eyeIcon={showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
            setshowPassword={setshowPassword}
          />
          <div className="btn_wrap">
            <CustomButton
              onClick={handleSubmit}
              className="submit"
              width="90px"
              title={loading ? <ButtonLoader /> : "Login"}
            />
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Login;
