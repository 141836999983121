import {
    CLEAR_DELETE_ORDER_STATE,
    CLEAR_ORDER_ERROR,
    CLEAR_ORDER_STATE,
    DELETE_ORDER_FAIL,
    DELETE_ORDER_REQUEST,
    DELETE_ORDER_SUCCESS,
    GET_ALL_ORDER_FAIL,
    GET_ALL_ORDER_REQUEST,
    GET_ALL_ORDER_SUCCESS,
    GET_ORDER_DETAIL_FAIL,
    GET_ORDER_DETAIL_REQUEST,
    GET_ORDER_DETAIL_SUCCESS,
    UPDATE_ORDER_FAIL,
    UPDATE_ORDER_REQUEST,
    UPDATE_ORDER_SUCCESS,
  } from "../constant/orderConstant";
  
  // GET ALL ORDERs
  export const getAllOrderReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_ALL_ORDER_REQUEST:
        return {
          loading: true,
          orderList: {},
        };
      case GET_ALL_ORDER_SUCCESS:
        return {
          loading: false,
          orderList: action.payload,
        };
      case GET_ALL_ORDER_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_ORDER_ERROR:
        return {
          loading: false,
          error: null,
        };
  
      case CLEAR_ORDER_STATE:
        return {
          loading: false,
          error: null,
          orderList: {},
        };
  
      default:
        return state;
    }
  };
  
  // GET ALL BATCH BY ID
  export const getOrderByIdReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_ORDER_DETAIL_REQUEST:
        return {
          loading: true,
          orderDetail: {},
        };
      case GET_ORDER_DETAIL_SUCCESS:
        return {
          loading: false,
          orderDetail: action.payload,
        };
      case GET_ORDER_DETAIL_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_ORDER_ERROR:
        return {
          loading: false,
          error: null,
        };
  
      case CLEAR_ORDER_STATE:
        return {
          loading: false,
          error: null,
          orderDetail: {},
        };
  
      default:
        return state;
    }
  };
  
  //   UPDATE BLOG REDUCER
  export const updateORDERReducer = (state = {}, action) => {
    switch (action.type) {
      case UPDATE_ORDER_REQUEST:
        return {
          loading: true,
          updateData: {},
        };
      case UPDATE_ORDER_SUCCESS:
        return {
          loading: false,
          updateData: action.payload,
        };
      case UPDATE_ORDER_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_ORDER_ERROR:
        return {
          loading: false,
          error: null,
        };
  
      case CLEAR_ORDER_STATE:
        return {
          loading: false,
          error: null,
          updateData: {},
        };
  
      default:
        return state;
    }
  };
  
  // DELETE BATCH BY ADMIN
  export const deleteORDERByAdminReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_ORDER_REQUEST:
        return {
          loading: true,
          ORDERDelete: {},
        };
      case DELETE_ORDER_SUCCESS:
        return {
          loading: false,
          ORDERDelete: action.payload,
        };
      case DELETE_ORDER_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_ORDER_ERROR:
        return {
          loading: false,
          error: null,
        };
      case CLEAR_DELETE_ORDER_STATE:
        return {
          loading: false,
          error: null,
          ORDERDelete: {},
        };
  
      default:
        return state;
    }
  };
  