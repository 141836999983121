import React, { Fragment } from "react";
import { logo } from "../../assests/imageModule/image";
import { Link } from "react-router-dom";
import { sideBarLinks } from "./headerLinks";
const Sidebar = () => {
  return (
    <Fragment>
      <div className="dahboard_sidebar">
        <div className="logo">
          <img src={logo} alt="adminUser" />
        </div>
        <ul className="nav_links">
          {sideBarLinks?.map((itm) => (
            <li key={itm?.id}>
              <Link to={`${itm?.url}`}>
              <span>{itm?.name}</span>
                {itm?.icon}
                
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  );
};

export default Sidebar;
