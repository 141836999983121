import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_DELETE_ORDER_STATE,
  CLEAR_ORDER_ERROR,
  CLEAR_ORDER_STATE,
  DELETE_ORDER_FAIL,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  GET_ALL_ORDER_FAIL,
  GET_ALL_ORDER_REQUEST,
  GET_ALL_ORDER_SUCCESS,
  GET_ORDER_DETAIL_FAIL,
  GET_ORDER_DETAIL_REQUEST,
  GET_ORDER_DETAIL_SUCCESS,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
} from "../constant/orderConstant";

// GET ALL BATCHES
export const getAllOrder = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_ORDER_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("saajAdminToken")
      ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/all-orders`, config);

    dispatch({
      type: GET_ALL_ORDER_SUCCESS,
      payload: data,
    });

  } catch (error) {
    dispatch({
      type: GET_ALL_ORDER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET BATCH BY ID
export const getOrderById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ORDER_DETAIL_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("saajAdminToken")
      ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/ORDER/${id}`, config);

    dispatch({
      type: GET_ORDER_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ORDER_DETAIL_FAIL,
      payload: error.response.data.message,
    });
  }
};

// UPDATE BLOG ACTION

export const updateOrder = (id, title) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_ORDER_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("saajAdminToken")
      ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.put(
      `/update-ORDER/${id}`,
      {
        title,
      },
      config
    );

    dispatch({
      type: UPDATE_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ORDER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// DELETE ORDER
export const deleteOrderByAdmin = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_ORDER_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("saajAdminToken")
      ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`/delete-ORDER/${id}`, config);

    dispatch({
      type: DELETE_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ORDER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearOrderErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ORDER_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearOrderState = () => async (dispatch) => {
  dispatch({ type: CLEAR_ORDER_STATE });
};

export const clearDeleteOrderState = () => async (dispatch) => {
  dispatch({ type: CLEAR_DELETE_ORDER_STATE });
};
