import { axiosInstance } from "../../utils/axiosConfig";
import {
  CLEAR_DELETE_PRODUCT_STATE,
  CLEAR_PRODUCT_ERROR,
  CLEAR_PRODUCT_STATE,
  CREATE_PRODUCT_FAIL,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  GET_ALL_PRODUCT_FAIL,
  GET_ALL_PRODUCT_REQUEST,
  GET_ALL_PRODUCT_SUCCESS,
  GET_PRODUCT_DETAIL_FAIL,
  GET_PRODUCT_DETAIL_REQUEST,
  GET_PRODUCT_DETAIL_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
} from "../constant/ProductConstant";

// CREATE NEW BATCH
export const createNewProduct =
  (
    // title,
    //       description,
    //       originalPrice,
    //       discountedPrice,
    //       stockQuantity,
    //       isTaxIncluded,
    //       isCodAvailable,
    //       category,
    //       sizes,
    //       images
    formData
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_PRODUCT_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("saajAdminToken")
        ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      // const availableSize = sizes?.join(",");

      const { data } = await axiosInstance.post(
        `/create-product`,
        formData,
        config
      );

      dispatch({
        type: CREATE_PRODUCT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_PRODUCT_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET ALL BATCHES
export const getAllProduct = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PRODUCT_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("saajAdminToken")
      ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/all-products`, config);

    dispatch({
      type: GET_ALL_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET BATCH BY ID
export const getProductById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PRODUCT_DETAIL_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("saajAdminToken")
      ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/blog/${id}`, config);

    dispatch({
      type: GET_PRODUCT_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_PRODUCT_DETAIL_FAIL,
      payload: error.response.data.message,
    });
  }
};

// UPDATE BLOG ACTION

export const updateProduct =
  (
    id,
    title,
    shortDescription,
    metaDescription,
    metaTitle,
    image,
    blogContent,
    authorId,
    socialLinksArray
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_PRODUCT_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("saajAdminToken")
        ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/blog/${id}`,
        {
          title,
          shortDescription,
          metaDescription,
          metaTitle,
          image,
          blogContent,
          authorId,
          socialLinksArray,
        },
        config
      );

      dispatch({
        type: UPDATE_PRODUCT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PRODUCT_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// DELETE PRODUCT
export const deleteProductByAdmin = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_PRODUCT_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("saajAdminToken")
      ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(
      `/delete-product/${id}`,
      config
    );

    dispatch({
      type: DELETE_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearProductErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_PRODUCT_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearProductState = () => async (dispatch) => {
  dispatch({ type: CLEAR_PRODUCT_STATE });
};

export const clearDeleteProductState = () => async (dispatch) => {
  dispatch({ type: CLEAR_DELETE_PRODUCT_STATE });
};
