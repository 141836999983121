
import { axiosInstance } from "../../utils/axiosConfig";
import {
  ALL_USERS_ADMIN_FAIL,
  ALL_USERS_ADMIN_REQUEST,
  ALL_USERS_ADMIN_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_PROFILE_ERRORS,
  CLEAR_STATE,
  CLEAR_UPDATE_STATE,
  CLEAR_USER_LOADING,
  DELETE_USER_ADMIN_FAIL,
  DELETE_USER_ADMIN_REQUEST,
  DELETE_USER_ADMIN_SUCCESS,
  GET_USER_DETAILS_ADMIN_FAIL,
  GET_USER_DETAILS_ADMIN_REQUEST,
  GET_USER_DETAILS_ADMIN_SUCCESS,
  UPDATE_USER_ADMIN_FAIL,
  UPDATE_USER_ADMIN_REQUEST,
  UPDATE_USER_ADMIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_PROFILE_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
} from "../constant/userConstant";






// USER LOGIN ACTION ==--==--==--==--==--==--==--
export const login = (email, password) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const { data } = await axiosInstance.post(
      `${process.env.REACT_APP_API}/login-with-pass`,
      {
        email,
        password,
      },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    sessionStorage.setItem(
      "saajAdminToken",
      JSON.stringify(getState().userLogin?.userInfo)
    );
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// USER LOGOUT
export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGOUT_REQUEST,
    });

    sessionStorage.removeItem("saajAdminToken");

    dispatch({ type: CLEAR_STATE });

    dispatch({
      type: USER_LOGOUT_SUCCESS,
      payload: null,
    });
  } catch (error) {
    alert(error);
  }
};

// GET USER PROFILE DETAILS
export const userProfile = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_PROFILE_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("saajAdminToken")
      ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_API}/me`,
      config
    );

    dispatch({
      type: USER_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET ALL USERS - BY ADMIN
export const getAllUsersByAdmin = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_USERS_ADMIN_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("saajAdminToken")
      ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/get-all-users`, config);

    dispatch({
      type: ALL_USERS_ADMIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_USERS_ADMIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// DELETE USER PROFILE DETAILS
export const deleteUserByAdmin = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_USER_ADMIN_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("saajAdminToken")
      ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`/delete-user/${id}`, config);

    dispatch({
      type: DELETE_USER_ADMIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_USER_ADMIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET USER PROFILE DETAILS
export const getUserDetailsByAdmin = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_DETAILS_ADMIN_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("saajAdminToken")
      ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/get-user-details/${id}`, config);

    dispatch({
      type: GET_USER_DETAILS_ADMIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_DETAILS_ADMIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET USER PROFILE DETAILS
export const updateUserDetailsByAdmin =
  (
    id,
    first_name,
    email,
    phone,
    dob,
    gender,
    address,
    middle_name,
    last_name,
    isActive,
    subSpec,
    teacherBio,
    assignSubjects,
    timeTable,
    thumbnail
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_USER_ADMIN_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("saajAdminToken")
        ? JSON.parse(sessionStorage.getItem("saajAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/update-user-details/${id}`,
        {
          first_name,
          email,
          phone,
          dob,
          gender,
          address,
          middle_name,
          last_name,
          isActive,
          subSpec,
          teacherBio,
          assignSubjects,
          timeTable,
          thumbnail,
        },
        config
      );

      dispatch({
        type: UPDATE_USER_ADMIN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_ADMIN_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// CLEAR PROFILE ERROR ACTION FUNCTION
export const clearProfileErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_PROFILE_ERRORS });
  sessionStorage.removeItem("saajAdminToken");
};

// CLEAR ERROR ACTION FUNCTION
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// CLEAR STATE ACTION FUNCTION
export const clearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
};

// CLEAR UPDATED STATE ACTION FUNCTION
export const clearUpdateState = () => async (dispatch) => {
  dispatch({ type: CLEAR_UPDATE_STATE });
};

// CLEAR USER LOADING ACTION FUNCTION
export const clearLoading = () => async (dispatch) => {
  dispatch({ type: CLEAR_USER_LOADING });
};
