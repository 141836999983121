export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";

export const ALL_USERS_ADMIN_REQUEST = "ALL_USERS_ADMIN_REQUEST";
export const ALL_USERS_ADMIN_SUCCESS = "ALL_USERS_ADMIN_SUCCESS";
export const ALL_USERS_ADMIN_FAIL = "ALL_USERS_ADMIN_FAIL";


export const DELETE_USER_ADMIN_REQUEST = "DELETE_USER_ADMIN_REQUEST";
export const DELETE_USER_ADMIN_SUCCESS = "DELETE_USER_ADMIN_SUCCESS";
export const DELETE_USER_ADMIN_FAIL = "DELETE_USER_ADMIN_FAIL";

export const GET_USER_DETAILS_ADMIN_REQUEST = "GET_USER_DETAILS_ADMIN_REQUEST";
export const GET_USER_DETAILS_ADMIN_SUCCESS = "GET_USER_DETAILS_ADMIN_SUCCESS";
export const GET_USER_DETAILS_ADMIN_FAIL = "GET_USER_DETAILS_ADMIN_FAIL";

export const UPDATE_USER_ADMIN_REQUEST = "UPDATE_USER_ADMIN_REQUEST";
export const UPDATE_USER_ADMIN_SUCCESS = "UPDATE_USER_ADMIN_SUCCESS";
export const UPDATE_USER_ADMIN_FAIL = "UPDATE_USER_ADMIN_FAIL";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const CLEAR_UPDATE_STATE = "CLEAR_UPDATE_STATE";



export const CLEAR_PROFILE_ERRORS = "CLEAR_PROFILE_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_STATE = "CLEAR_STATE";
export const CLEAR_USER_LOADING = "CLEAR_USER_LOADING";
