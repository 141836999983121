import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import Header from "../../components/layout/Header";
import { MdCategory } from "react-icons/md";
import CustomInput from "../../components/reusable/CustomInput";
import "../../styles/productCreate.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCATEGORYErrors,
  clearCATEGORYState,
  createNewCATEGORY,
} from "../../redux/actions/categoryAction";
import { toast } from "react-toastify";
import CustomButton from "../../components/reusable/CustomButton";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import { FaArrowLeftLong } from "react-icons/fa6";
const CategoryCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, categoryCreate } = useSelector(
    (state) => state.createCategory
  );
  const [title, setTitle] = useState("");

  const handleSubmit = () => {
    dispatch(createNewCATEGORY(title));
  };

  useEffect(() => {
    if (error) {
      dispatch(clearCATEGORYErrors());
      toast.error(error);
    }
    if (categoryCreate?.success) {
      toast.success(categoryCreate?.message);
      dispatch(clearCATEGORYState());
      navigate("/admin/category");
    }
  }, [
    dispatch,
    error,
    categoryCreate?.success,
    categoryCreate?.message,
    navigate,
  ]);

  return (
    <Fragment>
      <Layout>
        <Header title="Category Create" icon={<MdCategory />} />

        <div className="go_back">
          <button onClick={() => navigate(-1)}>
            <FaArrowLeftLong />
          </button>
        </div>
        <section className="product_create">
          <div className="input_grid">
            <CustomInput
              label="Categor Name"
              placeholder="Honey"
              name="title"
              type="text"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </div>
          <div className="submit_btn">
            <CustomButton
              title={loading ? <ButtonLoader /> : "Create"}
              width="90px"
              onClick={handleSubmit}
            />
          </div>
        </section>
      </Layout>
    </Fragment>
  );
};

export default CategoryCreate;
