import React, { Fragment } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import ProductList from "./pages/product/ProductList";
import { useSelector } from "react-redux";
import RedirectSpinner from "./components/reusable/RedirectSpinner";
import Loader from "./components/reusable/Loader";
import Login from "./pages/Login";
import ProductCreate from "./pages/product/ProductCreate";
import CategoryList from "./pages/category/CategoryList";
import CategoryCreate from "./pages/category/CreateCategory";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CategoryEdit from "./pages/category/EditCategory";
import OrderList from "./pages/order/OrderList";

const App = () => {
  const { loading, isAuthenticated, userInfo } = useSelector(
    (state) => state.profile
  );

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <ToastContainer />
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route
              path="/admin"
              element={
                isAuthenticated && userInfo?.user?.role === 10 ? (
                  <Outlet />
                ) : (
                  <RedirectSpinner />
                )
              }
            >
              <Route path="dashboard" element={<Dashboard />} />

              <Route path="product" element={<ProductList />} />
              <Route path="product-create" element={<ProductCreate />} />
              <Route path="order-history" element={<OrderList />} />

              <Route path="category" element={<CategoryList />} />
              <Route path="category-create" element={<CategoryCreate />} />

              <Route path="category-edit/:id" element={<CategoryEdit />} />
            </Route>
          </Routes>
        </div>
      )}
    </Fragment>
  );
};

export default App;
